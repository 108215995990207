<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <!-- <Button
                label="New"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              /> -->
              <!-- <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              /> -->
              
              &nbsp;
              <!-- <Button
                icon="pi pi-sync"
                class="p-button-info mr-2"
                @click="get_list"
              /> -->
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            /> -->
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :rowHover="true" showGridlines
        ><template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b> Manage Student Device Registration</b></h5>
              <h6 class="pull-right" >Total Records: {{totalRecords}}</h6>
              <Button icon="pi pi-refresh" @click="get_list()" />
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div>
          </template>

          <!-- <Column v-for="(col,index) in columns" :field="col" :header="col.toUpperCase()" :key="index"></Column> -->
          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
          <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>

          <Column header="ID" style="min-width: 3rem" :field="columns[0]">
            <template #body="{ data }">
              {{ data.id }}
            </template>
          </Column>
          <Column header="Device ID" style="min-width: 5rem" :field="columns[1]">
            <template #body="{ data }">
              {{ data.devicE_ID }}
            </template>
          </Column>
          
          <Column header="Student Name" style="min-width: 5rem" :field="columns[2]">
            <template #body="{ data }">
              {{ data.studenT_NAME }}
            </template>
          </Column>

          <Column header="STD" style="min-width: 5rem" :field="columns[3]">
            <template #body="{ data }">
              {{ data.std }}
            </template>
          </Column>
          <Column header="Stream" style="min-width: 5rem" :field="columns[4]">
            <template #body="{ data }">
              {{ data.stream }}
            </template>
          </Column>
          <Column header="Address" style="min-width: 5rem" :field="columns[5]">
            <template #body="{ data }">
              {{ data.address }}
            </template>
          </Column>
          <Column header="City" style="min-width: 5rem" :field="columns[6]">
            <template #body="{ data }">
              {{ data.city }}
            </template>
          </Column>
          <Column header="Mobile Number" style="min-width: 5rem" :field="columns[7]">
            <template #body="{ data }">
              {{ data.mobilE_NUMBER }}
            </template>
          </Column>
          <Column header="Contact No" style="min-width: 5rem" :field="columns[8]">
            <template #body="{ data }">
              {{ data.contacT_NO }}
            </template>
          </Column>
          <Column header="Created At" style="min-width: 5rem" :field="columns[9]">
            <template #body="{ data }">
              {{ data.createD_AT }}
            </template>
          </Column>
          <Column header="College Name" style="min-width: 5rem" :field="columns[10]">
            <template #body="{ data }">
              {{ data.collegE_NAME }}
            </template>
          </Column>
          <Column header="Pincode" style="min-width: 5rem" :field="columns[11]">
            <template #body="{ data }">
              {{ data.pincode }}
            </template>
          </Column>
          <Column header="Tab Contact Number" style="min-width: 5rem" :field="columns[12]">
            <template #body="{ data }">
              {{ data.taB_CONTACT_NUMBER }}
            </template>
          </Column>

          <Column header="Book Delivery Address" style="min-width: 5rem" :field="columns[15]">
            <template #body="{ data }">
              {{ data.bookdeliveryaddress }}
            </template>
          </Column>
          <Column header="Parent Name" style="min-width: 5rem" :field="columns[16]">
            <template #body="{ data }">
              {{ data.parentname }}
            </template>
          </Column>
          <Column header="Parent Mobile No." style="min-width: 5rem" :field="columns[17]">
            <template #body="{ data }">
              {{ data.parentmobile }}
            </template>
          </Column>
          <Column header="Network Provider" style="min-width: 5rem" :field="columns[22]">
            <template #body="{ data }">
              {{ data.networkprovider }}
            </template>
          </Column>

          

          <Column header="10th Cert." style="width: 4rem" >
            <template #body="{ data }">
              <a v-if="data.tentH_CERTIFICATE" :href="data.tentH_CERTIFICATE" target="_blank">View</a>
            </template>
          </Column>
          <!-- <Column header="12th Cert." style="width: 4rem" >
            <template #body="{ data }">
              <a v-if="data.twelvetH_CERTIFICATE" :href="data.twelvetH_CERTIFICATE" target="_blank">View</a>
            </template>
          </Column> -->
           <Column header="Non-Creamlayer" style="width: 4rem" >
            <template #body="{ data }">
              <a v-if="data.noncremiliarcertificate" :href="data.noncremiliarcertificate" target="_blank">View</a>
            </template>
          </Column>
          <Column header="Bonafied" style="width: 4rem" >
            <template #body="{ data }">
              <a v-if="data.bonafied" :href="data.bonafied" target="_blank">View</a>
            </template>
          </Column>
           <Column header="Adhar" style="width: 4rem" >
            <template #body="{ data }">
              <a v-if="data.aadharcard" :href="data.aadharcard" target="_blank">View</a>
            </template>
          </Column>
          <!-- <Column header="Photo" style="width: 4rem" >
            <template #body="{ data }">
              <a v-if="data.photo" :href="data.photo" target="_blank">View</a>
            </template>
          </Column> -->



          <!-- <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column> -->
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px', width: '450px' }"
          header="Syllabus Details"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 md:col-12">
              <div class="field">
                <!-- <img
            :src="'images/product/shoes.jpg'"
            :alt="product.image"
            width="150"
            class="mt-0 mx-auto mb-5 block shadow-2"
          /> -->
              </div>
              <div class="field">
                <label for="name">Name</label>
                <InputText
                  id="name"
                  v-model.trim="product.name"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.name }"
                />
                <small class="p-invalid" v-if="submitted && !product.name"
                  >Name is required.</small
                >
              </div>
              
              <!-- <div class="field">
                <label for="link">Link</label>
                <InputText
                  id="link"
                  v-model.trim="product.link"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.link }"
                />
              </div> -->
             
              
              <div class="field">
                <label for="file">PDF</label>
                <FileUpload
                  mode="basic"
                  id="file_attachment"
                  ref="file_attachment"
                  @change="handleFileUpload"
                  name="demo[]"
                  accept="application/pdf,image/jpeg,image/png"
                  :maxFileSize="1000000"
                />

                <img
                  :src="product.pdflink"
                  :alt="product.pdflink"
                  class="shadow-2"
                  width="100"
                  v-if="product.pdflink"
                />
              </div>

              <div class="field">
                <label for="status">Year</label>

                <Dropdown
                  id="state"
                  v-model="selectstatusyear"
                  :options="dropdownItemsyear"
                  :value="dropdownItemsyear.value"
                  optionLabel="name"
                  placeholder="Select One"
                  :class="{ 'p-invalid': submitted && !product.year }"
                  required="true"
                ></Dropdown>
              </div>
              
              <div class="field">
                <label for="status">Status</label>

                <Dropdown
                  id="state"
                  v-model="selectstatus"
                  :options="dropdownItems"
                  :value="dropdownItems.value"
                  optionLabel="name"
                  placeholder="Select One"
                  :class="{ 'p-invalid': submitted && !product.status }"
                  required="true"
                ></Dropdown>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <Button
                v-if="product.id"
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="AddSyllabus"
              />
              <Button
                v-else
                label="ADD"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="AddSyllabus"
              />
            </div>
            <div class="col-12 md:col-6">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
                @click="productDialog = false"
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deletesyllabus"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      page_no:1,
			isLoading:false,
			isLoadingModel:false,
			loading: false,
			fullPage:true,
      file_attachment: "",
      dropdownItems: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
				{ name: "Deleted", value: "Deleted" }
      ],
      dropdownItemsyear: [
        { name: 2023, value: 2023 },
        { name: 2022, value: 2022 },
				{ name: 2021, value: 2021 },
        { name: 2020, value: 2020 },
        { name: 2019, value: 2019 },
        { name: 2018, value: 2018 },
        { name: 2017, value: 2017 }
      ],
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },
      selectstatusyear: { name: "", value: "" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus"
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    handleFileUpload() {
      this.file_attachment = this.$refs.file_attachment.files[0];
      this.product.imagE_URL="";

      //alert(this.$refs.file_attachment.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      var data = {
        count: true,
      };
      this.loading = true;
      var promise = apis.stdentregistrationlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data;
        //alert(this.totalRecords);
      });

      console.log(data);
    },
    get_list: function () {
      var data = {
        Limit: this.limit,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.stdentregistrationlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.selectstatus={name:"",value:""};
       this.selectstatusyear={name:"",value:""};
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    AddSyllabus() {

        this.submitted = true;

        if(!this.product.name)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Name",
            life: 3000,
          });
          return false;
    }
    
    
    
    
    if (!this.product.id) {
    if(!this.file_attachment.name)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select image",
            life: 3000,
          });
          return false;
    }
    }
    if(!this.selectstatusyear.value)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select year",
            life: 3000,
          });
          return false;
    }
    if(!this.selectstatus.value)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select Status",
            life: 3000,
          });
          return false;
    }

     

      //edit
      if (this.product.id) {
        var file_name = "";
        if (this.file_attachment.type == undefined) {
          file_name = this.product.pdflink;
        } else {
          file_name = this.file_attachment.name;
        }

        var linke='https://s3.ap-south-1.amazonaws.com/sgberp.in/mahajyoti/syllabus/'+file_name;
        var data = {
          id: this.product.id,
          name: this.product.name,
          year:this.selectstatusyear.value,
          pdflink: file_name,
          
          updateD_BY: localStorage.getItem("id"),
          status: this.selectstatus.value,
           sortorder: 4
        };
        this.isLoadingModel = true;
        var promise = apis.editsyllabusmaster(data);
        promise
          .then((responseapi) => {
            if (responseapi.status == 200) {
              if (this.file_attachment.name) {
                // axios
                //   .post(
                //     "https://c9gqxigqgb.execute-api.ap-south-1.amazonaws.com/production/sgbfileupload/v1",

                //     {
                //       filePath:
                //         "mahajyoti/syllabus/" + this.file_attachment.name,
                //       contentType: this.file_attachment.type,
                //     }
                //   )

                var file_data={
                  filePath: "syllabus/" + this.file_attachment.name,
                  contentType: this.file_attachment.type,

                }
                var promise5 = apis.uploadFile(file_data);
                promise5
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachment, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachment.type,
                        },
                      })
                      .then((response) => {
                        this.$swal(responseapi.data);

                        this.productDialog = false;

                        this.isLoadingModel = false;
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        // $("#exampleModal").modal("hide");
                        //this.text = response.data.message;
                        //$("#error_modal").modal("show");
                        this.$swal("error to upload file");

                        //alert();
                      });
                  });
              } else {
                this.$swal(responseapi.data);
                // this.hideDialog1();
                this.get_list();
                this.productDialog = false;

                this.isLoadingModel = false;
              }
            } else {
              this.$swal(responseapi.data);
              // this.hideDialog1();
              this.productDialog = false;

              this.isLoadingModel = false;
            }
            //alert(this.totalRecords);
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
          });
      }
      //add
      else {
        var link='https://s3.ap-south-1.amazonaws.com/sgberp.in/mahajyoti/syllabus/'+this.file_attachment.name;
        var data1 = {
          name: this.product.name,
          pdflink: this.file_attachment.name,
         
          createD_BY: localStorage.getItem("id"),
          year: this.selectstatusyear.value,
          status: this.selectstatus.value,
          sortorder: 3

        };
        this.isLoadingModel = true;
        var promises = apis.addsyllabusmaster(data1);
        promises
          .then((responseapi) => {
            if (responseapi.status == 200) {
              if (this.file_attachment.name) {
                // axios
                //   .post(
                //     "https://c9gqxigqgb.execute-api.ap-south-1.amazonaws.com/production/sgbfileupload/v1",

                //     {
                //       filePath:
                //         "mahajyoti/syllabus/" + this.file_attachment.name,
                //       contentType: this.file_attachment.type,
                //     }
                //   )

                var file_data={
                  filePath: "syllabus/" + this.file_attachment.name,
                  contentType: this.file_attachment.type,

                }
                var promise4 = apis.uploadFile(file_data);
                promise4
                  .then((response) => {
                    axios
                      .put(response.data.url, this.file_attachment, {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          "Content-Type": this.file_attachment.type,
                        },
                      })
                      .then((response) => {
                        this.$swal(responseapi.data);

                        this.get_list();

                        this.productDialog = false;

                        this.isLoadingModel = false;
                      })
                      .catch((error) => {
                        this.isLoadingModel = false;
                        // $("#exampleModal").modal("hide");
                        //this.text = response.data.message;
                        //$("#error_modal").modal("show");
                        this.$swal("error to upload file");

                        //alert();
                      });
                  });
              } else {
                this.$swal(responseapi.data);
                // this.hideDialog1();
                this.productDialog = false;
                this.get_list();
                this.isLoadingModel = false;
              }
            } else {
              this.$swal(responseapi.data);
              // this.hideDialog1();
              this.productDialog = false;

              this.isLoadingModel = false;
            }
            //alert(this.totalRecords);
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
          });
      }
      this.selectstatus={name:"",value:""};
      this.selectstatusyear={name:"",value:""};
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.file_attachment = "";
      this.product = { ...product };
      
      
      this.selectstatus.value = this.product.status;
      this.selectstatus.name = this.product.status;

       this.selectstatusyear.value = this.product.year;
      this.selectstatusyear.name = this.product.year;

      this.file_attachment = this.product.pdflink;
      this.productDialog = true;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deletesyllabus() {
      if (this.product.id) {
        var data = {
          id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.deletesyllabusmaster(data);
        promise.then((responseapi) => {
          if (responseapi.status == 200) {
            this.deleteProductDialog = false;
            this.isLoadingModel = false;
            this.$swal(responseapi.data);
            // this.hideDialog1();
            
            this.get_list();
          } else {
            this.isLoadingModel = false;
            this.deleteProductDialog = false;
            this.$swal("error to upload file");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
     },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
